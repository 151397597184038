import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Cta from '../components/cta'
// Imported JSX component SEO must be in PascalCase
// Imported JSX component CTA must be in PascalCase

import '../styles/blogIndex.scss'

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout current='blog'>
      <Seo title='Blog' />
      <div class='section'>
        <h1>Blog</h1>
        {/* <Bio /> */}
        {posts.filter(({ node }) => node.frontmatter.route.includes('/blog')).map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article class='blogItem card' key={node.fields.slug}>
              <header>
                <h3>
                  <Link style={{ boxShadow: 'none' }} to={node.frontmatter.route}>
                    {title}
                  </Link>
                </h3>
                <small>{node.frontmatter.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt
                  }}
                />
              </section>
            </article>
          )
        })}
      </div>
      <Cta />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            route
          }
        }
      }
    }
  }
`
