import React from 'react'

import '../styles/cta.scss'

export default () => {
  return (
    <div class='section cta card'>
      <h2>Start monitoring your app today</h2>
      <p class='subtitle'>Free 30-days trial to get acquainted.</p>
      <a class='primary btn' href='https://dash.nucleus.sh/signup'>Start Now →</a>
    </div>
  )
}
